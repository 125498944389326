@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: normal;
  src: local("Mark Pro Regular"), url("../../fonts/MARKPRO.woff") format("woff"); }

* {
  font-family: 'Mark Pro', sans-serif !important; }

#root {
  height: 100vh; }

body {
  background-color: #FFF !important;
  margin: 0; }

.MuiInput-underline:before {
  border-bottom: 1px solid #FFF !important; }

.MuiSelect-icon {
  color: #FFF !important; }

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  height: 32px; }

.MuiTreeItem-label {
  height: 32px; }

.MuiTableCell-sizeSmall:last-child {
  padding-right: 0 !important; }

*::-webkit-scrollbar {
  width: 8px; }

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10rem;
  border: 1px solid #fff; }

*::-webkit-scrollbar-track-piece:start {
  background: transparent; }

*::-webkit-scrollbar-track-piece:end {
  background: transparent; }
